body {
  overscroll-behavior-y: contain;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  font-family: -apple-system,
      BlinkMacSystemFont,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
}

.video-container-intro {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; 
  width: 100%;
  height: 100%; 
  overflow: hidden;
  background-image: url('./assets/welcome-cover.jpg');
  background-size: cover;
  background-position: center center;
} 

.video-container video,
.video-container-intro video {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.3);
}